.row {
  color: white;
  cursor: pointer;
  margin: 4px;
  margin-bottom: 4px;
  border-radius: 3px;
  transition: all 0.35s;
  overflow: hidden;
  padding: 10px;
  display: flex;
  font-family: "ClashDisplay-medium";
}
.row.expanded {
  padding: 10px;
}
.row.collapsed {
  height: 0px;
  padding: 0px 10px;
  margin: 0px 2px;
}
.row:hover {
  .deleteIconTableRow {
    display: block;
  }
}
.childlessRow {
  display: flex;
  transition: all 0.35s;
  overflow: hidden;
  padding-left: 20px;
  background-color: white;
  color: #003366;
  font-size: 14px;
}
.childlessRow .plan {
  background-color: #eeeeee;
  transition: background-color 0.15s ease-in-out;
  color: #585858;
  padding: 0.18rem 0.35rem;
  border-radius: 6px;
  margin-left: 10px;
  margin-top: -1px;
  display: flex;
  align-items: center;
  align-self: top;
  height: fit-content;
  font-size: 12px;
  cursor: pointer;
}
.childlessRow .plan:hover {
  background-color: #1d77b7;
  color: white;
}
.childlessRow .plan:hover svg path {
  stroke: white;
}
.childlessRow .rating {
  margin-bottom: -3px;
}
.childlessRow.expanded {
  padding-right: 0px;
  margin: 10px 5px 15px 40px;
}
.childlessRow.collapsed {
  padding-right: 0px;
  margin: 0px 5px 0px 40px;
}
.deleteIcon {
  fill: #585858;
  margin-left: 8px;
  margin-top: 2px;
  cursor: pointer;
}
.importantIcon {
  fill: gold;
  margin-left: 4px;
  margin-right: -2px;
  margin-top: 2px;
  cursor: pointer;
  path {
    stroke: #585858;
  }
}
.importantIcon:hover {
  path {
    stroke: rgb(226, 129, 50);
  }
}
.deleteIconTableRow {
  fill: white;
  margin-left: 6px;
  cursor: pointer;
  display: none;
  align-self: center;
}
.deleteIconTableRow:hover {
  fill: rgb(220, 220, 220);
}
.deleteIcon:hover {
  fill: red;
}
.noteIcon {
  position: absolute;
  left: 35px;
  cursor: pointer;
}
.noteIcon:hover {
  stroke: gold;
  stroke-width: 8px;
}
.indentedListHeader {
  display: flex;
  align-items: center;
  padding: 10px 10px 8px;
  justify-content: space-between;
}
